<template>
  <div class="container py-12 !max-w-4xl">
    <!-- action bar -->

    <div class="flex justify-between">
      <El-Button @click="router.push({ name: 'Note.List' })">
        <font-awesome-icon :icon="['fas', 'reply']" class="mr-2" />
        回筆記列表</El-Button
      >
      <div class="flex">
        <El-Button
          type="primary"
          :disabled="!note.row.member_status"
          @click="openComment"
          >編輯我的註解</El-Button
        >
        <div class="ml-4">
          <el-input
            v-model="keyword"
            placeholder="搜尋筆記"
            clearable
            @clear="onClear"
            @keyup.enter="search"
          >
            <template #append>
              <el-button :icon="Search" type="primary" @click="search" />
            </template>
          </el-input>
        </div>
      </div>
    </div>

    <div class="fixed right-0 top-[10rem] md:top-[14rem]">
      <div
        class="bg-primary p-4 md:p-2 md:text-xs rounded-l rounded-sm text-white cursor-pointer"
        @click="catalog.toggle"
      >
        筆<br />記<br />目<br />錄
      </div>
    </div>
    <el-drawer
      v-model="catalog.show"
      :with-header="false"
      :size="isMobile ? '80%' : '30%'"
      @opened="expand"
    >
      <div class="h-full w-full flex flex-col">
        <div class="flex flex-none">
          <div
            class="inline-block ml-auto cursor-pointer"
            @click="catalog.toggle"
          >
            <font-awesome-icon :icon="['fas', 'times']" />
          </div>
        </div>
        <el-scrollbar class="flex-grow h-full">
          <CatalogTree ref="treeRef" />
        </el-scrollbar>
      </div>
    </el-drawer>

    <div class="text-4xl text-primary mt-8">{{ note.row.title }}</div>
    <div v-if="!note.row.all" class="text-center">目前沒有內容</div>
    <div v-else-if="note.row.published == 0" class="text-center">
      筆記內容更新中
    </div>
    <div v-else v-loading="loading" class="content mt-12">
      <h2
        class="text-2xl font-bold py-2 px-4 text-primary bg-background-2 rounded"
      >
        {{ note.row.part }}
      </h2>
      <p class="mt-4 text-xl font-bold pl-4 text-primary-2">
        {{ note.row.chapter }}
      </p>
      <p class="mt-4 text-lg font-bold pl-4 text-primary-2">
        {{ note.row.section }}
      </p>
      <div
        class="mt-4"
        v-html="isMobile ? note.row.mobile : note.row.web"
      ></div>
    </div>

    <div class="flex justify-end mt-6">
      <div class="flex space-x-3">
        <El-Button
          type="primary"
          :disabled="!note.row.member_status"
          @click="openComment"
          >編輯我的註解</El-Button
        >
      </div>
    </div>
    <div v-if="note.row.filterIds?.length" class="mt-12">
      <div class="text-xl">相關試題</div>
      <div class="mt-8">
        <a
          v-for="filterItem in note.row.filterIds"
          :key="filterItem.id"
          href="#"
          class="text-lg py-3 mb-4 mr-4 px-6 rounded-full shadow duration-300 hover:shadow-lg text-primary bg-white"
          @click.prevent="
            $router.push({
              name: filterItem.type === 'sbj' ? 'Exam.Question' : 'Exam.List',
              params: { slug: filterItem.type },
              query: {
                type: filterItem.type,
                pg: 1,
                filterId: filterItem.id,
              },
            })
          "
        >
          {{ filterItem.name }}
        </a>
      </div>
    </div>
    <!-- <img src="@/assets/imgs/notedemo.png" alt="" /> -->
    <Pagination
      v-if="note.row.all > 0"
      :total="note.row.all"
      :current-page="currentPage || 1"
      class="mt-8"
      @page-changed="_updatePg"
    ></Pagination>
  </div>
</template>

<script setup>
import { ElInput, ElDrawer, ElScrollbar } from 'element-plus';
import { Search } from '@element-plus/icons';
import Pagination from '@/components/Pagination.vue';

import { provide, inject, computed, reactive, ref, onMounted } from 'vue';
import _ from 'lodash';
import useNote from '@/modules/note/useNote';
import useCatalog from '@/modules/note/useCatalog';
import { useRoute, useRouter } from 'vue-router';
import CatalogTree from '@/modules/note/Catalog.vue';
const route = useRoute();
const router = useRouter();
const isMobile = inject('isMobile');
const permission = inject('permission');

const note = useNote();
const { loading } = note;
provide('note', note);

const keyword = ref('');
const catalog = reactive({
  ...useCatalog(note.row),
  show: false,
  toggle: () => {
    catalog.show = !catalog.show;
  },
});
provide('catalog', catalog);

const search = async () => {
  catalog.form.keyword = keyword.value;
  await catalog.fetcher();
  catalog.toggle();
};

const onClear = () => {
  keyword.value = '';
  catalog.form.keyword = keyword.value;
  catalog.fetcher();
};

const treeRef = ref(null);
const expand = () => {
  treeRef.value.expand();
};

const currentPage = computed(() => {
  return Number(route.query.pg);
});

const _updatePg = async (pg) => {
  if (!note.row.member_status) {
    if (!(await permission.dialog(pg <= note.row.limit))) return;
  }

  if (!pg || currentPage.value === pg) return;
  note.updatePg(pg);
};
provide('updatePg', _updatePg);

const commentDialog = inject('commentDialog');
const openComment = () => {
  commentDialog.id = route.params?.id;
  commentDialog.toggle({ show: true });
};

onMounted(() => {
  note.fetcher();
  catalog.fetcher();
});
</script>

<style lang="sass" scoped>
:deep(.el-tree)
  .el-tree-node
    @apply mt-1
  >.el-tree-node
    @apply mt-6
</style>
<style lang="scss" scoped>
:deep(.el-tree) {
  .arrow {
    @apply relative pointer-events-none;
    &:before {
      content: '→';
      @apply absolute top-0 left-[-18px] text-red-600 font-bold;
    }
  }
}
</style>
